import React from "react";
import { Button } from "@/Components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/Components/ui/card";
import { CreditCard, Gift, Zap, ArrowRight } from "lucide-react";
import { Head, router } from "@inertiajs/react";
import { TreasureChest } from "@phosphor-icons/react";

export default function Welcome() {
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white">
            <Head title="Home" />
            <header className="container mx-auto px-4 py-6 flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <TreasureChest className="h-8 w-8 text-primary" />
                    <span className="text-2xl font-bold">InkRewards</span>
                </div>
                <Button
                    variant="outline"
                    onClick={() => {
                        router.visit(route("login"));
                    }}
                >
                    Login
                </Button>
            </header>

            <main className="container mx-auto px-4 py-12">
                <section className="text-center mb-16">
                    <h1 className="text-4xl font-bold mb-4">
                        Enjoy Discounts with Every Purchase
                    </h1>
                    <p className="text-xl text-muted-foreground mb-8">
                        Join InkRewards and start getting discounts on all your
                        transactions
                    </p>
                </section>

                <section className="grid md:grid-cols-3 gap-8 mb-16">
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <Zap className="mr-2 h-5 w-5 text-primary" />
                                Tier-Based Discounts
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <CardDescription>
                                With InkRewards, customers get discounts on
                                each purchase based on the tier group their
                                transaction qualifies for. Higher spending tiers
                                unlock greater discounts.
                            </CardDescription>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <Gift className="mr-2 h-5 w-5 text-primary" />
                                Increasing Discounts with Each Tier
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <CardDescription>
                                Each tier offers a progressively larger
                                discount based on transaction amounts, making
                                purchases more rewarding as customers reach
                                higher levels.
                            </CardDescription>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <CreditCard className="mr-2 h-5 w-5 text-primary" />
                                Effortless Discount Application
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <CardDescription>
                                Discounts are applied automatically at checkout
                                based on the transaction amount, so customers
                                can enjoy instant savings without any extra
                                steps. The discount is calculated on each
                                purchase in-store.
                            </CardDescription>
                        </CardContent>
                    </Card>
                </section>

                <section className="text-center mb-16">
                    <h2 className="text-3xl font-bold mb-4">How It Works</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <div className="bg-primary text-primary-foreground rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                                1
                            </div>
                            <h3 className="font-semibold mb-2">
                                Apply for an InkRewards Account
                            </h3>
                            <p className="text-muted-foreground">
                                Users must sign up in-store to activate their
                                InkRewards account, which tracks purchases and
                                assigns discounts based on spending tiers.
                            </p>
                        </div>
                        <div>
                            <div className="bg-primary text-primary-foreground rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                                2
                            </div>
                            <h3 className="font-semibold mb-2">Spend</h3>
                            <p className="text-muted-foreground">
                                Use your InkRewards Account for all purchases,
                                by scanning your QR code at checkout.
                            </p>
                        </div>
                        <div>
                            <div className="bg-primary text-primary-foreground rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                                3
                            </div>
                            <h3 className="font-semibold mb-2">
                                Get Discount
                            </h3>
                            <p className="text-muted-foreground">
                                For each purchase, users can earn discounts
                                depending on the tier their transaction falls
                                under.
                            </p>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="bg-gray-100 py-8 mt-16">
                <div className="container mx-auto px-4 text-center text-muted-foreground">
                    <p>&copy; 2024-2025 InkRewards. All rights reserved.</p>
                    <div className="mt-4">
                        <a
                            href="#"
                            className="text-primary hover:underline mr-4"
                        >
                            Terms of Service
                        </a>
                        <a
                            href="#"
                            className="text-primary hover:underline mr-4"
                        >
                            Privacy Policy
                        </a>
                        <a href="#" className="text-primary hover:underline">
                            Contact Us
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}
